GraphQL ID: templates
list:

  - class: Mesmer
    builds:

      - name: Condi Virtuoso Dueling
        id: cvirt
        specialization: Virtuoso
        boonType: Condi
        priority: Condi DPS Rampager
        distribution: Condi Virtuoso Dueling
        traits: Condi Virtuoso Dueling
        extras: Condi Virtuoso Dueling
        weaponType: Dual wield
        outdated: true

      # - name: Condi Virtuoso (sharpening sorrow)
      #   id: cvirt-sorrow
      #   specialization: Virtuoso
      #   boonType: Condi
      #   priority: Condi DPS Rampager
      #   distribution: Condi Virtuoso (sharpening sorrow)
      #   traits: Condi Virtuoso (sharpening sorrow)
      #   extras: Condi Virtuoso Dueling
      #   weaponType: Dual wield

      - name: Condi Virtuoso Chaos
        id: cvirt-chaos
        specialization: Virtuoso
        boonType: Condi
        priority: Condi DPS Rampager
        distribution: Condi Virtuoso Chaos
        traits: Condi Virtuoso Chaos
        extras: Condi Virtuoso Chaos
        weaponType: Dual wield
        outdated: true

      - name: Power Virtuoso Spear
        id: pvirt-spear
        specialization: Virtuoso
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Virtuoso Spear
        traits: Power Virtuoso Spear
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, furious)
        weaponType: Two-handed
        outdated: true

      - name: Power Virtuoso GS
        id: pvirt-gs
        specialization: Virtuoso
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Virtuoso GS
        traits: Power Virtuoso GS
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, furious)
        weaponType: Two-handed
        outdated: true

      - name: Boon Power Chrono Spear
        id: pchrono-boon-spear
        specialization: Chronomancer
        boonType: Power
        priority: Power DPS
        distribution: Boon Power Chrono Spear
        traits: Boon Power Chrono Spear
        fractal:
          extras: Power Boon (Fractal)
        raid:
          extras: Power Boon (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Boon Power Chrono GS
        id: pchrono-boon-gs
        specialization: Chronomancer
        boonType: Power
        priority: Power DPS
        distribution: Boon Power Chrono GS
        traits: Boon Power Chrono GS
        fractal:
          extras: Power Boon (Fractal)
        raid:
          extras: Power Boon (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Boon Condi Chrono
        id: condi-chrono-stm
        specialization: Chronomancer
        boonType: Condi
        priority: Boon Condi Chrono 10%
        distribution: Boon Condi Chrono
        traits: Boon Condi Chrono
        extras: Boon Condi Chrono
        weaponType: Dual wield
        outdated: true

      # - name: Alacrity Power Chrono GS
      #   id: pchrono-alac-gs
      #   specialization: Chronomancer
      #   boonType: Power
      #   priority: Boon Power Chrono 0%
      #   distribution: Alacrity Power Chrono GS
      #   traits: Alacrity Power Chrono GS
      #   fractal:
      #     extras: Power Boon (Fractal)
      #   raid:
      #     extras: Power Boon (Raid)
      #   weaponType: Two-handed
      #   outdated: true

      - name: Power Chrono IA GS
        id: pchrono-ia-gs
        specialization: Chronomancer
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Chrono IA GS
        traits: Power Chrono IA GS
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, furious)
        weaponType: Two-handed
        outdated: true

      # - name: Power Chrono DT Focus
      #   id: pchrono-dt-focus
      #   specialization: Chronomancer
      #   boonType: Power
      #   priority: Power DPS (no dragon)
      #   distribution: Power Chrono DT Focus
      #   traits: Power Chrono DT Focus
      #   fractal:
      #     extras: Power (Fractal)
      #   raid:
      #     extras: Power (Raid, furious)
      #   weaponType: Two-handed
      #   outdated: true

      # - name: Power Chrono IA Focus
      #   id: pchrono-ia-focus
      #   specialization: Chronomancer
      #   boonType: Power
      #   priority: Power DPS (no dragon)
      #   distribution: Power Chrono IA Focus
      #   traits: Power Chrono IA Focus
      #   fractal:
      #     extras: Power (Fractal)
      #   raid:
      #     extras: Power (Raid, furious)
      #   weaponType: Two-handed
      #   outdated: true


      - name: Alacrity Staff Mirage
        id: alacmirage
        specialization: Mirage
        boonType: Condi
        priority: Condi Alacrity Mirage 0%
        distribution: Alacrity Staff Mirage
        traits: Alacrity Staff Mirage
        extras: Alacrity Staff Mirage
        weaponType: Two-handed
        outdated: true

      # - name: Alacrity Staff / Axe Mirage
      #   id: alac-staxe-mirage
      #   specialization: Mirage
      #   boonType: Condi
      #   priority: Condi Alacrity Mirage 0%
      #   distribution: Alacrity Staff / Axe Mirage
      #   traits: Alacrity Staff / Axe Mirage
      #   extras: Alacrity Staff Mirage
      #   weaponType: Two-handed
      #   outdated: true

      - name: Condi Staff / Axe Mirage
        id: condi-staxe-mirage
        specialization: Mirage
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Staff / Axe Mirage
        traits: Condi Staff / Axe Mirage
        extras: DPS Mirage (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Condi Axe Mirage
        id: axe-mirage-deception-torch
        specialization: Mirage
        boonType: Condi
        priority: Condi DPS
        distribution: Axe Mirage (Deception Torch)
        traits: Axe Mirage
        extras: DPS Mirage (Raid)
        weaponType: Dual wield
        outdated: true

      - name: Heal Boon Chrono
        id: heal-boon-chrono
        specialization: Chronomancer
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Chrono
        extras: Heal
        weaponType: Two-handed
        outdated: false

  - class: Guardian
    builds:

      # - name: Quickness Condi Firebrand 40%
      #   id: cqfb-firebrand-40
      #   specialization: Firebrand
      #   boonType: Condi
      #   priority: Condi Quickbrand 40%
      #   distribution: Condi Quickbrand (LL, Allies, Approx.)
      #   traits: Condi Quickbrand LL
      #   extras: Condi Quickbrand
      #   weaponType: Dual wield
      #   outdated: true

      # - name: Quickness Condi Firebrand 49%
      #   id: cqfb-firebrand-49
      #   specialization: Firebrand
      #   boonType: Condi
      #   priority: Condi Quickbrand 49
      #   distribution: Condi Quickbrand (WT, Allies)
      #   traits: Condi Quickbrand WT
      #   extras: Condi Quickbrand
      #   weaponType: Dual wield
      #   outdated: true

      - name: Quickess Condi Firebrand P/T P/P
        id: cqfb-firebrand-40
        specialization: Firebrand
        boonType: Condi
        priority: Condi Quickbrand 40%
        distribution: Quickess Condi Firebrand P/T P/P
        traits: Quickess Condi Firebrand P/T P/P
        extras: Quickess Condi Firebrand
        weaponType: Dual wield
        outdated: false

      - name: Quickness Power Firebrand
        id: pqfb
        specialization: Firebrand
        boonType: Power
        priority: Power Quickbrand 25%
        distribution: Power Quickbrand (approx.)
        traits: Power Quickbrand
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Quickbrand (Raid)
        weaponType: Two-handed
        outdated: true

      # - name: CFB w/ Quickness (balthazar)
      #   id: cqfb-balth
      #   specialization: Firebrand
      #   boonType: Condi
      #   priority: Condi DPS
      #   distribution: Condi Quickbrand (allies)
      #   traits: Condi Quickbrand
      #   extras: CFB w/ Quickness (balthazar)

      # - name: CFB w/ Quickness (renegade)
      #   id: cqfb-ren
      #   specialization: Firebrand
      #   boonType: Condi
      #   priority: Condi DPS
      #   distribution: Condi Quickbrand (allies)
      #   traits: Condi Quickbrand
      #   extras: CFB w/ Quickness (renegade)

      - name: Condi Firebrand A/T P/P
        id: cfb-geo
        specialization: Firebrand
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Firebrand A/T P/P
        traits: Condi Firebrand Virtues
        extras: Condi Firebrand
        weaponType: Dual wield
        outdated: true

      - name: Condi Firebrand A/T
        id: cfb-axe-only
        specialization: Firebrand
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Firebrand A/T
        traits: Condi Firebrand Zeal
        extras: Condi Firebrand
        weaponType: Dual wield
        outdated: false

      - name: Power DH Radiance
        id: dh-radiance
        specialization: Dragonhunter
        boonType: Power
        priority: Power DPS
        distribution: DH Radiance
        traits: DH Radiance
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Power DH Virtues
        id: dh-virtues
        specialization: Dragonhunter
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: DH Virtues
        traits: DH Virtues
        fractal:
          extras: DH Virtues (Fractal)
        raid:
          extras: DH Virtues (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Alacrity Power Willbender
        id: pwb-alac
        specialization: Willbender
        boonType: Power
        priority: Power Alacrity Willbender 14%
        distribution: Power Alacrity Willbender
        traits: Power Alacrity Willbender
        fractal:
          extras: Power Willbender Virtues (Fractal)
        raid:
          extras: Power Alacrity Willbender (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Alacrity Condi Willbender
        id: cwb-alac
        specialization: Willbender
        boonType: Condi
        priority: Condi Alacrity Willbender 14%
        distribution: Condi Alacrity Willbender
        traits: Condi Alacrity Willbender
        extras: Condi Alacrity Willbender
        weaponType: Dual wield
        outdated: true

      - name: Condi Willbender P/T P/P
        id: cwb-pistol
        specialization: Willbender
        boonType: Condi
        priority: Hybrid DPS
        distribution: Condi Willbender P/T P/P
        traits: Condi Willbender
        extras: Condi Willbender
        weaponType: Dual wield
        outdated: false

      - name: Condi Willbender GS
        id: cwb-gs
        specialization: Willbender
        boonType: Condi
        priority: Hybrid DPS
        distribution: Condi Willbender GS
        traits: Condi Willbender
        extras: Condi Willbender
        weaponType: Two-handed
        outdated: true

      - name: Power Willbender Virtues
        id: pwb-virtues
        specialization: Willbender
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Willbender Virtues
        traits: Power Willbender Virtues
        fractal:
          extras: Power Willbender Virtues (Fractal)
        raid:
          extras: Power (Raid, furious)
        weaponType: Two-handed
        outdated: true

      - name: Power Willbender Radiance
        id: pwb-radiance
        specialization: Willbender
        boonType: Power
        priority: Power DPS
        distribution: Power Willbender Radiance
        traits: Power Willbender Radiance
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Power Core Guardian
        id: core-guard
        specialization: Guardian
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Core Guardian
        traits: Core Guardian
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      # - name: Condi Quickbrand Celestial 35%
      #   id: cqfb-cele-35
      #   specialization: Firebrand
      #   boonType: Condi
      #   priority: Condi Quickbrand 35%
      #   distribution: Condi Quickbrand (allies)
      #   traits: Condi Quickbrand
      #   extras: Condi Quickbrand 35%

      - name: Hybrid FB Virtues
        id: hycfb-virtues-leadership
        specialization: Firebrand
        boonType: Condi
        priority: Condi Hybrid Firebrand 40%
        distribution: Hybrid FB Virtues
        traits: Hybrid FB Virtues
        extras: Hybrid FB Traveler
        weaponType: Two-handed
        outdated: true

      - name: Hybrid FB Honor
        id: hycfb-honor
        specialization: Firebrand
        boonType: Condi
        priority: Condi Hybrid Firebrand 78%
        distribution: Hybrid FB (Honor, Allies)
        traits: Hybrid FB Honor
        extras: Hybrid FB Traveler
        weaponType: Two-handed
        outdated: true

      - name: Heal Alacrity Willbender
        id: heal-willbender
        specialization: Willbender
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Alacrity Willbender
        extras: Heal
        weaponType: Two-handed
        outdated: false

      - name: Heal Quickness Firebrand
        id: hb
        specialization: Firebrand
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Healbrand
        extras: Heal
        weaponType: Two-handed
        outdated: false

  - class: Warrior
    builds:

      - name: Alacrity Power Bladesworn
        id: bls-alac
        specialization: Bladesworn
        boonType: Power
        priority: Power DPS
        distribution: Power Alacrity Bladesworn
        traits: Alacrity Bladesworn
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Bladesworn
        weaponType: Dual wield
        outdated: true

      - name: Power Bladesworn
        id: bls-dps
        specialization: Bladesworn
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: DPS Bladesworn Tactics
        traits: DPS Bladesworn Tactics
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Bladesworn
        weaponType: Dual wield
        outdated: true

      - name: Quickness Condi Berserker
        id: cbers
        specialization: Berserker
        boonType: Condi
        priority: Condi Quickness Berserker 44.7%
        distribution: Condi Quickness Bers
        traits: Condi Quickness Berserker
        extras: Condi Quickness Bers
        weaponType: Two-handed
        outdated: true

      - name: Condi Berserker
        id: cbers-dps
        specialization: Berserker
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Berserker
        traits: Condi Berserker
        extras: Condi Berserker
        weaponType: Two-handed
        outdated: true

      - name: Quickness Power Berserker GS A/A
        id: pbers-quick
        specialization: Berserker
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Quickness Power Berserker GS A/A
        traits: Quickness Power Berserker GS A/A
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Berserker
        weaponType: Two-handed
        outdated: false

      - name: Power Berserker GS A/A
        id: pbers
        specialization: Berserker
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Berserker GS A/A
        traits: Power Berserker GS A/A
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Berserker
        weaponType: Two-handed
        outdated: false

      - name: Power Spellbreaker GS
        id: spb-power-gs
        specialization: Spellbreaker
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Spellbreaker GS
        traits: Power Spellbreaker GS
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Power Spellbreaker Hammer
        id: spb-power-hammer
        specialization: Spellbreaker
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Spellbreaker Hammer
        traits: Power Spellbreaker Hammer
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, claw)
        weaponType: Two-handed
        outdated: false

      - name: Heal Quickness Berserker
        id: heal-berserker
        specialization: Berserker
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Quickness Berserker
        extras: Heal
        weaponType: Two-handed
        outdated: false

  - class: Elementalist
    builds:

      - name: Alacrity Condi Tempest Pistol
        id: ctemp-alac
        specialization: Tempest
        boonType: Condi
        priority: Alacrity Condi Tempest 40%
        distribution: Alacrity Condi Tempest Pistol
        traits: Alacrity Condi Tempest Pistol
        extras: Condi Tempest
        weaponType: Dual wield
        outdated: true

      - name: Alacrity Power Tempest Scepter
        id: ptemp-alac
        specialization: Tempest
        boonType: Power
        priority: Alacrity Power Tempest 28%
        distribution: None
        traits: Alacrity Power Tempest Arcane
        fractal:
          extras: Alacrity Power Tempest (Fractal)
        raid:
          extras: Alacrity Power Tempest (Raid)
        weaponType: Dual wield
        outdated: true

      - name: Condi Tempest Pistol
        id: ctemp
        specialization: Tempest
        boonType: Condi
        priority: Condi Tempest
        distribution: Condi Tempest Pistol
        traits: Condi Tempest Pistol
        extras: Condi Tempest
        weaponType: Dual wield
        outdated: true

      - name: Power Tempest Scepter
        id: ptemp
        specialization: Tempest
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Tempest Scepter
        traits: Power Tempest Water
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Dual wield
        outdated: true

      - name: Power Weaver Sword
        id: pwea
        specialization: Weaver
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Weaver (BTTH, small)
        fractal:
          traits: Power Weaver Stormsoul Fractal
          extras: Power (Fractal)
        raid:
          traits: Power Weaver Raid
          extras: Power (Raid, fireworks)
        weaponType: Dual wield
        outdated: true

      - name: Condi Weaver Pistol (Approx.)
        id: cwea-pi
        specialization: Weaver
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Weaver Pistol
        traits: Condi Weaver Pistol
        extras: Condi Weaver
        weaponType: Dual wield
        outdated: false

      - name: Condi Weaver Staff
        id: cwea-staff
        specialization: Weaver
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Weaver Staff (Large)
        traits: Condi Weaver Staff
        extras: Condi Weaver
        weaponType: Two-handed
        outdated: true

      - name: Condi Weaver Scepter
        id: cwea-scepter
        specialization: Weaver
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Weaver Scepter
        traits: Condi Weaver Scepter
        extras: Condi Weaver Scepter
        weaponType: Dual wield
        outdated: true

      - name: Condi Weaver Sword
        id: cwea-sw
        specialization: Weaver
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Weaver Sword
        traits: Condi Weaver Sword
        extras: Condi Weaver Sword
        weaponType: Dual wield
        outdated: true

      - name: Condi Weaver Dagger
        id: cwea-dg
        specialization: Weaver
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Weaver (Dagger)
        traits: Condi Weaver Dagger
        extras: Condi Weaver Dagger
        weaponType: Dual wield
        outdated: true

      - name: Hybrid Weaver
        id: hwea
        specialization: Weaver
        boonType: Condi
        priority: Hybrid DPS
        distribution: Hybrid Weaver
        traits: Hybrid Weaver
        fractal:
          extras: Hybrid Weaver (Fractal)
        raid:
          extras: Hybrid Weaver (Raid)
        weaponType: Dual wield
        outdated: true

      - name: Quickness Power Catalyst
        id: power-cat-quick
        specialization: Catalyst
        boonType: Power
        priority: Power Quickness Catalyst 8.3%
        distribution: Power Catalyst
        traits: Power Quickness Catalyst
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Quickness Catalyst (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Power Catalyst
        id: power-cat
        specialization: Catalyst
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Catalyst
        traits: Power Catalyst
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Heal Alacrity Tempest
        id: healtemp
        specialization: Tempest
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Tempest
        extras: Heal
        weaponType: Dual wield
        outdated: false

  - class: Ranger
    builds:

      - name: Quickness Condi Untamed
        id: condi-quickness-untamed
        specialization: Untamed
        boonType: Condi
        priority: Condi Quickness Untamed 30%
        distribution: Condi Quickness Untamed
        traits: Condi Quickness Untamed
        extras: Condi Quickness Untamed
        weaponType: Two-handed
        outdated: true

      - name: Quickness Power Untamed
        id: quickness-power-untamed
        specialization: Untamed
        boonType: Power
        priority: Power Quickness Untamed 40%
        distribution: 100% Power
        traits: Quickness Power Untamed
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Quickness Untamed (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Power Untamed
        id: power-untamed
        specialization: Untamed
        boonType: Power
        priority: Power DPS
        distribution: 100% Power
        traits: Power Untamed
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Power Soulbeast
        id: pslb
        specialization: Soulbeast
        boonType: Power
        priority: Power DPS
        distribution: Power Soulbeast
        traits: Power Soulbeast
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, fireworks)
        weaponType: Two-handed
        outdated: true

      - name: Hybrid Soulbeast A/T D/A (Approx.)
        id: hslb
        specialization: Soulbeast
        boonType: Condi
        priority: Hybrid DPS
        distribution: Hybrid Slb A/T D/A
        traits: Hybrid Slb
        extras: Hybrid Slb
        weaponType: Dual wield
        outdated: false

      # - name: Condi Soulbeast D/T SB
      #   id: cslb-sb
      #   specialization: Soulbeast
      #   boonType: Condi
      #   priority: Condi DPS
      #   distribution: Condi Slb (D/T SB)
      #   traits: Condi Slb SB
      #   extras: Condi Slb
      #   weaponType: Two-handed
      #   outdated: true

      # - name: Condi Soulbeast D/T A/D
      #   id: cslb-dtad
      #   specialization: Soulbeast
      #   boonType: Condi
      #   priority: Condi DPS
      #   distribution: Condi Slb (D/T A/D)
      #   traits: Condi Slb D/T A/D

      - name: Condi Soulbeast D/D SB (Approx.)
        id: cslb-ddsb
        specialization: Soulbeast
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Slb D/D SB
        traits: Condi Slb D/D SB
        extras: Condi Slb
        weaponType: Dual wield
        outdated: false

      - name: Condi Druid
        id: condidruid
        specialization: Druid
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Druid
        traits: Condi Druid
        extras: Condi Druid
        weaponType: Two-handed
        outdated: true

      - name: Heal Alacrity Druid
        id: druid
        specialization: Druid
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Druid
        extras: Heal
        weaponType: Two-handed
        outdated: false

  - class: Revenant
    builds:

      - name: Quickness Power Herald
        id: herald-quick
        specialization: Herald
        boonType: Power
        priority: Power DPS
        distribution: Power Quickness Herald
        traits: Power Quickness Herald
        fractal:
          extras: Power Quickness Herald (Fractal)
        raid:
          extras: Power Quickness Herald (Raid)
        weaponType: Two-handed
        outdated: false

      - name: Quickness Condi Herald SB M/A
        id: cherald-quick
        specialization: Herald
        boonType: Condi
        priority: Condi Quickness Herald 0%
        distribution: Condi Quickness Herald SB M/A
        traits: Condi Quickness Herald
        extras: Condi Quickness Herald
        weaponType: Two-handed
        outdated: false

      - name: Power Herald FP
        id: herald
        specialization: Herald
        boonType: Power
        priority: Power DPS
        distribution: Power Herald FP
        traits: Power Herald FP
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, air)
        weaponType: Dual wield
        outdated: true

      - name: Alacrity Condi Renegade
        id: cren-alac-invo
        specialization: Renegade
        boonType: Condi
        priority: Alacrity Condi Renegade 42%
        distribution: Alacrity Condi Renegade
        traits: Condi Alac Ren Invo
        extras: Alacrity Condi Renegade
        weaponType: Two-handed
        outdated: false

      - name: Alacrity Power Renegade
        id: pren
        specialization: Renegade
        boonType: Power
        priority: Power Alacrity Renegade 37.5%
        distribution: Alacrity Renegade
        traits: Power Alac Ren
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Alac Ren (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Condi Renegade Invo
        id: cren-invo
        specialization: Renegade
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Renegade Invocation
        traits: Condi DPS Ren Invo
        extras: Condi Ren
        weaponType: Two-handed
        outdated: true

      - name: Power Vindicator
        id: vindi
        specialization: Vindicator
        boonType: Power
        priority: Power DPS
        distribution: DPS Vindicator
        traits: DPS Vindicator
        fractal:
          extras: DPS Vindicator (Fractal)
        raid:
          extras: DPS Vindicator (Raid)
        weaponType: Two-handed
        outdated: false

      - name: Heal Alacrity Renegade
        id: healren
        specialization: Renegade
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Ren
        extras: Heal
        weaponType: Two-handed
        outdated: false

      - name: Heal Quickness Herald
        id: heal-herald
        specialization: Herald
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Herald
        extras: Heal
        weaponType: Two-handed
        outdated: false

  - class: Engineer
    builds:
      - name: Quickness Power Scrapper (Approx.)
        id: pqscrap
        specialization: Scrapper
        boonType: Power
        priority: Power DPS
        distribution: Power Scrapper
        traits: Quickness Power Scrapper
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, fireworks)
        weaponType: Two-handed
        outdated: false

      - name: Quickness Condi Scrapper
        id: cqscrap
        specialization: Scrapper
        boonType: Condi
        priority: Condi Quickness Scrapper 28.7%
        distribution: Quickness Condi Scrapper
        traits: Quickness Condi Scrapper
        extras: Condi Quickness Scrapper
        weaponType: Two-handed
        outdated: true

      - name: Power Scrapper
        id: pscrap
        specialization: Scrapper
        boonType: Power
        priority: Power DPS
        distribution: Power Scrapper
        traits: Power Scrapper
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, fireworks)
        weaponType: Two-handed
        outdated: false

      - name: Power Holo ECSU
        id: pholo-ecsu
        specialization: Holosmith
        boonType: Power
        priority: Power DPS
        distribution: Power Holo ECSU
        traits: Power Holo ECSU
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Holo (Raid)
        weaponType: Dual wield
        outdated: true

      - name: Power Holo PBM
        id: pholo-pbm
        specialization: Holosmith
        boonType: Power
        priority: Power DPS
        distribution: Power Holo PBM
        traits: Power Holo PBM
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Holo (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Condi Holosmith Spear
        id: cholo
        specialization: Holosmith
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Holo
        traits: Condi Holo
        extras: Condi Holo
        weaponType: Two-handed
        outdated: true

      # - name: Alacrity Power Mech (inaccurate)
      #   id: mech-alac-power
      #   specialization: Mechanist
      #   boonType: Condi
      #   priority: Power Alacrity Mechanist 55%
      #   distribution: Power Alacrity Mechanist (inaccurate)
      #   traits: Power Alacrity Mechanist (inaccurate)
      #   fractal:
      #     extras: Power Alacrity Mechanist (Fractal, inaccurate)
      #   raid:
      #     extras: Power Alacrity Mechanist (Raid, inaccurate)
      #   weaponType: Dual wield
      #   outdated: true

      - name: Alacrity Power Mech Air
        id: mech-alac-power
        specialization: Mechanist
        boonType: Condi
        priority: Power Alacrity Mechanist 55%
        distribution: No Simulation
        traits: Power Alacrity Mechanist (inaccurate)
        fractal:
          extras: Power Alacrity Mechanist (Fractal, inaccurate)
        raid:
          extras: Power Alacrity Mechanist (Raid, air, inaccurate)
        weaponType: Dual wield
        outdated: true

      - name: Alacrity Power Mech Conc
        id: mech-alac-power-conc
        specialization: Mechanist
        boonType: Condi
        priority: Power Alacrity Mechanist 55%
        distribution: No Simulation
        traits: Power Alacrity Mechanist (inaccurate)
        fractal:
          extras: Power Alacrity Mechanist (Fractal, inaccurate)
        raid:
          extras: Power Alacrity Mechanist (Raid, concentration, inaccurate)
        weaponType: Dual wield
        outdated: true

      # - name: Condi Mech J-Drive (Approx.)
      #   id: cmech-sig-mace
      #   specialization: Mechanist
      #   boonType: Condi
      #   priority: Condi DPS
      #   distribution: Condi Mechanist J-Drive (approx.)
      #   traits: Condi Mechanist J-Drive
      #   extras: Condi Mechanist
      #   weaponType: Dual wield
      #   outdated: false

      # - name: Condi Mech Jade Dynamo (Approx.)
      #   id: cmech-dynamo-pistol
      #   specialization: Mechanist
      #   boonType: Condi
      #   priority: Condi DPS
      #   distribution: Condi Mechanist Jade Dynamo Pistol (approx.)
      #   traits: Condi Mechanist Jade Dynamo
      #   extras: Condi Mechanist
      #   weaponType: Dual wield
      #   outdated: true

      # - name: Condi Alac Mech (inaccurate)
      #   id: cmech-alac-condi
      #   specialization: Mechanist
      #   boonType: Condi
      #   priority: Condi Alacrity Mechanist 50%
      #   distribution: Condi Alac Mechanist (inaccurate)
      #   traits: Condi Alac Mechanist (inaccurate)
      #   extras: Condi Alac Mechanist (inaccurate)
      #   weaponType: Dual wield
      #   outdated: true

      - name: Heal Quickness Scrapper
        id: healscrapper
        specialization: Scrapper
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Scrapper
        extras: Heal
        weaponType: Dual wield
        outdated: false

      - name: Heal Alacrity Mech
        id: healmech
        specialization: Mechanist
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Mechanist
        extras: Heal
        weaponType: Dual wield
        outdated: false

  - class: Necromancer
    builds:

      - name: Quickness Condi Harbinger
        id: qharb
        specialization: Harbinger
        boonType: Condi
        priority: Condi Quickness Harbinger 12.8%
        distribution: Quickness Condi Harbinger
        traits: Quickness Condi Harbinger
        extras: Quickness Condi Harbinger
        weaponType: Dual wield
        outdated: false

      - name: Quickness Power Harbinger
        id: qharb-power
        specialization: Harbinger
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Quickness Power Harbinger
        traits: Quickness Power Harbinger
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, fireworks)
        weaponType: Two-handed
        outdated: false

      - name: Condi Harbinger
        id: charb
        specialization: Harbinger
        boonType: Condi
        priority: Condi DPS
        distribution: DPS Harbinger
        traits: DPS Harbinger
        extras: DPS Harbinger
        weaponType: Dual wield
        outdated: false

      - name: Power Harbinger
        id: pharb
        specialization: Harbinger
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Harbinger
        traits: Power Harbinger
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, fireworks)
        weaponType: Two-handed
        outdated: false

      - name: Alacrity Condi Scourge
        id: scourge-alac
        specialization: Scourge
        boonType: Condi
        priority: Condi Alacrity Scourge 28.8%
        distribution: Condi Alacrity Scourge
        traits: Condi Alacrity Scourge
        extras: DPS Scourge
        weaponType: Dual wield
        outdated: false

      - name: Condi Scourge
        id: scourge
        specialization: Scourge
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Scourge
        traits: DPS Scourge
        extras: DPS Scourge
        weaponType: Dual wield
        outdated: false

      - name: Power Reaper
        id: preaper
        specialization: Reaper
        boonType: Power
        priority: Power DPS
        distribution: Power Reaper
        traits: Power Reaper
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid, fireworks)
        weaponType: Two-handed
        outdated: true

      - name: Condi Reaper Spear
        id: creaper-spear
        specialization: Reaper
        boonType: Condi
        priority: Hybrid DPS
        distribution: Condi Reaper Spear
        traits: Condi Reaper Spear
        extras: Condi Reaper Spear
        weaponType: Two-handed
        outdated: false

      - name: Condi Reaper Scepter
        id: creaper
        specialization: Reaper
        boonType: Condi
        priority: Hybrid DPS
        distribution: Condi Reaper Scepter
        traits: Condi Reaper Scepter
        extras: Condi Reaper Scepter
        weaponType: Two-handed
        outdated: false

      - name: Heal Alacrity Scourge
        id: healscourge
        specialization: Scourge
        boonType: Power
        fractal:
          priority: Heal (Fractal)
        raid:
          priority: Heal (Raid)
        distribution: None
        traits: Heal Scourge
        extras: Heal
        weaponType: Dual wield
        outdated: false

  - class: Thief
    builds:

      - name: Alacrity Specter SC/D 57%
        id: specter-alac
        specialization: Specter
        boonType: Condi
        priority: Condi Alacrity Specter 57%
        distribution: Alacrity Specter SC/D (allies)
        traits: Alacrity Specter SC/D
        extras: Alacrity Specter
        weaponType: Dual wield
        outdated: true

      - name: Alacrity Specter D/D
        id: specter-alac-dd
        specialization: Specter
        boonType: Condi
        priority: Condi Alacrity Specter 42%
        distribution: Alacrity Specter D/D (allies)
        traits: Alacrity Specter D/D
        extras: Alacrity Specter
        weaponType: Dual wield
        outdated: false

      - name: Condi Specter Spear
        id: specter-spear
        specialization: Specter
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Specter Spear (Allies)
        traits: Condi Specter Spear
        extras: Condi Specter Spear
        weaponType: Two-handed
        outdated: false

      - name: Condi Specter SC/D
        id: specter
        specialization: Specter
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Specter SC/D (Allies)
        traits: Condi Specter SC/D
        extras: Condi Specter SC/D
        weaponType: Dual wield
        outdated: false

      - name: Condi Barrier "Carrion" Specter SC/D
        id: specter-carrion
        specialization: Specter
        boonType: Condi
        priority: Condi Barrier Specter
        distribution: DPS Barrier Specter SC/D (Allies)
        traits: Condi Specter SC/D
        extras: Condi Barrier Specter SC/D
        weaponType: Dual wield
        outdated: true

      - name: Boon Condi Daredevil (no log)
        id: boondd-condi
        specialization: Daredevil
        boonType: Condi
        priority: Condi Boon Daredevil 62%
        distribution: None
        traits: Condi Boon Daredevil
        extras: Condi Boon Daredevil
        weaponType: Dual wield
        outdated: true

      - name: Boon Power Daredevil
        id: boondd-power
        specialization: Daredevil
        boonType: Power
        priority: Power Boon Daredevil 99.7%
        distribution: Power Staff Daredevil
        traits: Power Boon Daredevil
        extras: Power Boon Daredevil
        weaponType: Two-handed
        outdated: true

      - name: Condi Daredevil
        id: cdd
        specialization: Daredevil
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Daredevil
        traits: Condi Daredevil
        extras: Condi Daredevil
        weaponType: Dual wield
        outdated: true

      - name: Power Daredevil Staff
        id: staffdd
        specialization: Daredevil
        boonType: Power
        priority: Power DPS
        distribution: Power Staff Daredevil
        traits: Power Staff Daredevil
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Staff Daredevil
        weaponType: Two-handed
        outdated: false

      - name: Quickness Condi Deadeye Spear
        id: quick-cded-spear
        specialization: Deadeye
        boonType: Condi
        priority: Condi DPS
        distribution: Quickness Condi Deadeye Spear
        traits: Quickness Condi Deadeye Spear
        extras: Quickness Condi Deadeye Spear
        weaponType: Two-handed
        outdated: false

      - name: Quickness Power Deadeye Spear
        id: quick-pded-spear
        specialization: Deadeye
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Quickness Power Deadeye Spear
        traits: Quickness Power Deadeye Spear
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Deadeye (Raid)
        weaponType: Two-handed
        outdated: false

      - name: Condi Deadeye A/D
        id: cded
        specialization: Deadeye
        boonType: Condi
        priority: Condi DPS
        distribution: Condi Deadeye A/D
        traits: Condi Deadeye A/D
        extras: Condi Deadeye A/D
        weaponType: Dual wield
        outdated: false

      - name: Power Deadeye Rifle
        id: pded-m7
        specialization: Deadeye
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Deadeye Rifle
        traits: Power Deadeye Rifle
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Deadeye (Raid)
        weaponType: Two-handed
        outdated: false

      - name: Power Deadeye Spear
        id: pded-spear
        specialization: Deadeye
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Deadeye Spear
        traits: Power Deadeye Spear
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power Deadeye (Raid)
        weaponType: Two-handed
        outdated: false

      # - name: Power Deadeye Daggers BQoBK
      #   id: pded-daggers-bqobk
      #   specialization: Deadeye
      #   boonType: Power
      #   priority: Power DPS (no dragon)
      #   distribution: Power Deadeye Daggers BQoBK
      #   traits: Power Deadeye Daggers BQoBK
      #   fractal:
      #     extras: Power (Fractal)
      #   raid:
      #     extras: Power (Raid)
      #   weaponType: Dual wield
      #   outdated: true

      - name: Power Deadeye Rifle Silent Scope
        id: rifleded-silent
        specialization: Deadeye
        boonType: Power
        priority: Power DPS (no dragon)
        distribution: Power Deadeye Rifle Silent Scope
        traits: Power Deadeye Rifle Silent Scope
        fractal:
          extras: Power (Fractal)
        raid:
          extras: Power (Raid)
        weaponType: Two-handed
        outdated: true

      - name: Heal Alacrity Specter
        id: heal-specter
        specialization: Specter
        boonType: Power
        priority: Heal Specter 88%
        distribution: None
        traits: Heal Specter
        extras: Heal
        weaponType: Dual wield
        outdated: false
