- section: Skills
  items:

    - id: signet-of-domination
      text: Signet of Domination
      modifiers:
        attributes:
          Condition Damage: [180, buff]
      gw2id: 10232
      temporaryBuff: false

    - id: signet-of-midnight
      text: Signet of Midnight
      modifiers:
        attributes:
          Expertise: [180, buff]
      gw2id: 10234
      temporaryBuff: false

    - id: fractured-glass
      text: Fractured Glass
      subText: with Mirage Mantle
      amountData:
        label: '% uptime'
        default: 0
        defaultInput: ???
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [25%, unknown]
      gw2id: 73067

- section: Domination
  id: 10
  items:

    - id: empowered-illusions
      text: Empowered Illusions
      modifiers:
        damage:
          Outgoing Phantasm Damage: [15%, unknown]
      gw2id: 682
      defaultEnabled: true

    - id: fragility
      text: Fragility
      minor: true
      amountData:
        label: 'vulnerability'
        default: 25
        quantityEntered: 25
      modifiers:
        damage:
          Outgoing Strike Damage: [12.5%, mult]
      gw2id: 1941
      defaultEnabled: true

    - id: egotism
      text: Egotism
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 713
      defaultEnabled: true

    - id: vicious-expression
      text: Vicious Expression
      amountData:
        label: '% boonless'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
          Outgoing Phantasm Damage: [15%, unknown]
      gw2id: 681
      defaultEnabled: true

- section: Dueling
  id: 1
  items:

    - id: phantasmal-fury
      text: Phantasmal Fury
      subText: normal
      modifiers:
        attributes:
          Phantasm Critical Chance: 25%
      wvwModifiers:
        attributes:
          Phantasm Critical Chance: 20%
      gw2id: 701
      defaultEnabled: false
      temporaryBuff: false

    - id: phantasmal-fury-virtuoso
      text: Phantasmal Fury
      subText: virtuoso
      modifiers:
        attributes:
          Phantasm Critical Chance: 40%
      wvwModifiers:
        attributes:
          Phantasm Critical Chance: 35%
      gw2id: 701
      defaultEnabled: false
      temporaryBuff: false

    - id: phantasmal-fury-virtuoso-bugged
      text: Phantasmal Fury
      subText: 'virtuoso (bugged version)'
      modifiers:
        attributes:
          Phantasm Critical Chance: 35%
      wvwModifiers:
        attributes:
          Phantasm Critical Chance: 30%
      gw2id: 701
      defaultEnabled: false
      temporaryBuff: false

    - id: sharper-images-phantasm
      text: Sharper Images
      subText: phantasm
      minor: true
      amountData:
        label: 'hit/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Phantasm Critical Chance: 500%}
      gw2id: 710
      defaultEnabled: false
      temporaryBuff: true

    - id: sharper-images-clone
      text: Sharper Images
      subText: clone
      minor: true
      amountData:
        label: 'hit/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Clone Critical Chance: 500%}
      gw2id: 710
      defaultEnabled: false
      temporaryBuff: true

    - id: fencers-finesse
      text: Fencer's Finesse
      amountData:
        label: 'stacks'
        default: 10
        quantityEntered: 10
      modifiers:
        attributes:
          Ferocity: [150, buff]
      gw2id: 708
      defaultEnabled: true
      temporaryBuff: true

    - id: superiority-complex-base
      text: Superiority Complex
      subText: base
      modifiers:
        damage:
          Outgoing Critical Damage: [15%, mult]
      gw2id: 692
      defaultEnabled: true

    - id: superiority-complex
      text: Superiority Complex
      subText: bonus
      amountData:
        label: '% below 50/disabled'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          # increases base version to 25%: 1.25/1.15 = 1.08695652174. see GitHub PR #612.
          Outgoing Critical Damage: [8.695652174%, mult]
      gw2id: 692
      defaultEnabled: true

- section: Chaos
  id: 45
  items:

    - id: illusionary-membrane
      text: Illusionary Membrane
      minor: true
      amountData:
        label: '% chaos aura'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Condition Damage: [7%, add]
      wvwModifiers:
        damage:
          Outgoing Condition Damage: [10%, add]
      gw2id: 667
      defaultEnabled: true

    - id: chaotic-potency
      text: Chaotic Potency
      subText: base
      modifiers:
        attributes:
          Condition Damage: [120, buff]
      gw2id: 669
      defaultEnabled: true
      temporaryBuff: false

    # no configurable amount; causes scaled boon duration with toxic oil
    - id: chaotic-potency-2
      text: Chaotic Potency
      subText: with staff
      # amountData:
      #   label: '% uptime'
      #   default: 100
      #   quantityEntered: 100
      modifiers:
        attributes:
          Condition Damage: [120, converted] # tested by marcus
      gw2id: 669
      defaultEnabled: false
      temporaryBuff: activeOutOfCombat # sometimes...

    - id: chaotic-persistence
      text: Chaotic Persistence
      minor: true
      subText: 100% regeneration
      modifiers:
        attributes:
          Concentration: [250, converted]
          Expertise: [250, converted]
          # converted: https://discord.com/channels/380901000200060929/875327743334707251/911570311177207818
      gw2id: 1865
      defaultEnabled: true
      temporaryBuff: true

- section: Inspiration
  id: 23
  items:

    - id: illusionary-inspiration
      text: Illusionary Inspiration
      modifiers:
        attributes:
          Outgoing Healing: 15%
      gw2id: 1866
      defaultEnabled: true
      temporaryBuff: false

- section: Illusions
  id: 24
  items:

    - id: compounding-power
      text: Compounding Power
      subText: 5x
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 5
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
          Outgoing Condition Damage: [10%, add]
      minor: true
      gw2id: 723
      defaultEnabled: true

    - id: Phantasmal Force
      text: Phantasmal Force
      amountData:
        label: 'might'
        default: 25
        quantityEntered: 25
      modifiers:
        damage:
          Outgoing Phantasm Damage: [25%, unknown]
      gw2id: 733
      defaultEnabled: true

    - id: malicious-sorcery
      text: Malicious Sorcery
      modifiers:
        attributes:
          Confusion Duration: 25%
      gw2id: 753
      defaultEnabled: true
      temporaryBuff: false

- section: Chronomancer
  id: 40
  note: Be sure to change the power coefficient value below when adding or removing traits that change your rotation like improved alacrity.
  items:

    - id: danger-time
      text: Danger Time
      subText: 100% slow
      modifiers:
        attributes:
          Critical Chance: 15%
          Phantasm Critical Chance: 15%
          Clone Critical Chance: 15%
      gw2id: 2009
      defaultEnabled: true
      temporaryBuff: true

    - id: improved-alacrity
      text: Improved Alacrity
      subText: critical damage bonus only
      modifiers:
        damage:
          Outgoing Critical Damage: [10%, unknown]
      gw2id: 1978
      defaultEnabled: true

    - id: chronophantasma
      text: Chronophantasma
      modifiers:
        damage:
          Outgoing Phantasm Damage: [100%, mult]
      wvwModifiers:
        damage:
          Outgoing Phantasm Damage: [50%, mult]
      gw2id: 1890
      defaultEnabled: true

- section: Mirage
  id: 59
  items:

    - id: nomads-endurance
      text: Nomad's Endurance
      amountData:
        label: '% vigor'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add] # unconfirmed
          Outgoing Condition Damage: [10%, add] # unconfirmed
      minor: true
      gw2id: 2069
      defaultEnabled: true
      temporaryBuff: true

- section: Virtuoso
  id: 66
  items:

    - id: mental-focus
      text: Mental Focus
      amountData:
        label: '% melee'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult] # tested by Snappy Joe
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 2208
      defaultEnabled: true

    - id: jagged-mind
      text: Jagged Mind
      subText: per blade hit (including non critical)
      amountData:
        label: 'hit/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 400%}
      wvwModifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 300%}
      gw2id: 2202
      defaultEnabled: false
      temporaryBuff: true

    # does not affect lifesteal despite "all text" description
    # https://discord.com/channels/380901000200060929/1017105862357176341/1017167619478003792
    - id: deadly-blades
      text: Deadly Blades
      minor: true
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, add] # probably
          Outgoing Condition Damage: [5%, add] #unconfirmed
      gw2id: 2204
      defaultEnabled: true

    - id: sharpening-sorrow
      text: Sharpening Sorrow
      subText: 100% fury
      modifiers:
        attributes:
          Expertise: [150, unknown]
      gw2id: 2207
      defaultEnabled: true
      temporaryBuff: true

    - id: quiet-intensity
      text: Quiet Intensity
      subText: base
      minor: true
      modifiers:
        conversion:
          Ferocity: {Vitality: 10%}
      gw2id: 2193
      defaultEnabled: true
      temporaryBuff: false

    - id: quiet-intensity-fury
      text: Quiet Intensity
      subText: 100% fury
      minor: true
      modifiers:
        attributes:
          Critical Chance: 15%
      wvwModifiers:
        attributes:
          Critical Chance: 10%
      gw2id: 2193
      defaultEnabled: true
      temporaryBuff: true

    - id: infinite-forge
      text: Infinite Forge
      amountData:
        label: '% strike damage from blade skills'
        default: 0
        defaultInput: ???
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [7%, mult] # unconfirmed
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult] # unconfirmed
      gw2id: 2206
      defaultEnabled: true

    - id: bloodsong
      text: Bloodsong
      modifiers:
        damage:
          Outgoing Bleeding Damage: [25%, mult] # unconfirmed
      gw2id: 2223
      defaultEnabled: true